<template>
  <div>
    <el-row>
      <el-col :span="3" style="margin-right: 15px">
        <el-select
          v-model="search.market_id"
          clearable
          placeholder="请选择所属市场"
        >
          <el-option
            v-for="item in Form.marketid"
            :key="item.id"
            :label="item.comp_name"
            :value="item.id"
            @click.native="clickmarketid"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="margin-right: 15px">
        <el-select v-model="search.seller_id" placeholder="请选择所属商户">
          <el-option
            v-for="item in Form.sellerid"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="margin-right: 15px">
        <el-cascader
          :options="Form.detectionProject"
          :props="{ value: 'id', label: 'name' }"
          clearable
          v-model="search.detection_project_id"
          placeholder="请选择检测项目"
        ></el-cascader>
      </el-col>
      <el-col :span="3" style="margin-right: 15px">
        <el-select v-model="search.result" clearable placeholder="请选择经营户">
          <el-option label="合格" value="1"> </el-option>
          <el-option label="不合格" value="0"> </el-option>
          <el-option label="疑似" value="2"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="5" style="margin-right: 15px">
        <el-date-picker
          v-model="search.date"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="4" style="margin-right: 15px">
        <el-button @click="GET_information">查找</el-button>
        <el-button type="primary" @click="centerDialogVisible = true"
          >新增</el-button
        >
      </el-col>
    </el-row>
    <el-table
      :data="tableData"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center' }"
      border
      style="width: 100%;margin-top:20px"
    >
      <el-table-column prop="id" label="检测信息ID" width="100px">
      </el-table-column>
      <el-table-column prop="" label="检测商品">
        <template slot-scope="scope">
          <span>{{ scope.row.veg_name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="project_name" label="检测项目"> </el-table-column>
      <el-table-column prop="value" label="检测值" width="100px">
      </el-table-column>
      <el-table-column label="检测人员">
        <template slot-scope="scope">
          <span>{{
            scope.row.detection_people.name
              ? scope.row.detection_people.name
              : ''
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="result" label="检测结论">
        <template slot-scope="scope">
          <span v-if="scope.row.result == 1">合格</span>
          <span v-if="scope.row.result == 0">不合格</span>
          <span v-if="scope.row.result == 2">疑似</span>
        </template>
      </el-table-column>
      <el-table-column prop="market_name" label="市场">
        <template slot-scope="scope">
          <span>{{ scope.row.market ? scope.row.market.comp_name : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="商户">
        <template slot-scope="scope">
          <span>{{ scope.row.seller ? scope.row.seller.name : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="检测时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="alterChang(scope.row)"
            >修 改</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="search.page"
        :page-sizes="[10, 20, 30]"
        :page-size="search.perPage"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
      @close="closecenterDialogVisible"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
      >
        <div
          style=" display:flex;justify-content: space-between;padding:0 50px"
        >
          <div>
            <el-form-item label="所属市场" prop="market_id">
              <el-select v-model="ruleForm.market_id" placeholder="请选择市场">
                <el-option
                  v-for="item in Form.marketid"
                  :key="item.id"
                  :label="item.comp_name"
                  :value="item.id"
                  @click.native="clickmarketid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属商户" prop="seller_id">
              <el-select
                v-model="ruleForm.seller_id"
                placeholder="请选择所属商户"
              >
                <el-option
                  v-for="item in Form.sellerid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="clicksellerid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商户店铺" prop="seller_shop_id">
              <el-select
                v-model="ruleForm.seller_shop_id"
                placeholder="请选择所属商户店铺"
              >
                <el-option
                  v-for="item in Form.sellershopid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="clicksellershopid"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商户商品" prop="product_seller_id">
              <el-select
                v-model="ruleForm.product_seller_id"
                placeholder="请输入检测名称"
              >
                <el-option
                  v-for="item in Form.productsellerid.data"
                  :key="item.id"
                  :label="item.veg_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="检测名称" prop="detect_name">
              <el-input
                v-model="ruleForm.detect_name"
                style="width:217px"
                placeholder="请输入检测名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="进货批次" prop="batch_id">
              <el-input
                v-model="ruleForm.batch_id"
                style="width:217px"
                placeholder="请输入检测名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="检测值" prop="value">
              <el-input
                v-model="ruleForm.value"
                style="width:217px"
                placeholder="请输入检测名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="检测商品图片" prop="detection_product_image">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile1"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.detection_product_image"
                  :src="ruleForm.detection_product_image"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="样品编号" prop="sample_id">
              <el-input
                v-model="ruleForm.sample_id"
                style="width:217px"
                placeholder="请输入检测名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="检测项目" prop="detection_project_id">
              <div>
                <el-cascader
                  :options="Form.detectionProject"
                  :props="{ value: 'id', label: 'name' }"
                  clearable
                  :show-all-levels="false"
                  v-model="ruleForm.detection_project_id"
                ></el-cascader>
                <el-button
                  type="primary"
                  @click="innerVisible = true"
                  size="medium"
                  >编 辑</el-button
                >
              </div>
            </el-form-item>
            <el-form-item label="检测机构" prop="detection_organization_id">
              <el-select
                v-model="ruleForm.detection_organization_id"
                placeholder="请选择检测机构"
              >
                <el-option
                  v-for="item in Form.detectionorganizationid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="peoples"
                >
                </el-option>
              </el-select>
              <el-button
                type="primary"
                @click="organization1 = true"
                size="medium"
                >编 辑</el-button
              >
            </el-form-item>
            <el-form-item
              label="检测人员"
              prop="detection_people_id"
              v-if="ruleForm.detection_organization_id"
            >
              <el-select
                v-model="ruleForm.detection_people_id"
                placeholder="请选择检测人员"
              >
                <el-option
                  v-for="item in Form.detectionpeopleid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-button type="primary" @click="people1 = true" size="medium"
                >编 辑</el-button
              >
            </el-form-item>
            <el-form-item label="检测日期" prop="date">
              <el-date-picker
                v-model="ruleForm.date"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="检测编号" prop="detection_num">
              <el-input
                v-model="ruleForm.detection_num"
                style="width:217px"
                placeholder="请输入检测名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="检测结果" prop="result">
              <el-select
                v-model="ruleForm.result"
                placeholder="请选择所属商户店铺"
              >
                <el-option label="不合格" value="0"> </el-option>
                <el-option label="合格" value="1"> </el-option>
                <el-option label="疑似" value="2"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="检测报告图片" prop="detection_report_image">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile2"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.detection_report_image"
                  :src="ruleForm.detection_report_image"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
        </div>
        <div style="padding:0 50px">
          <el-form-item label="检测结果说明" prop="result_expl">
            <el-input
              v-model="ruleForm.result_expl"
              type="textarea"
              resize="none"
              maxlength="300"
              show-word-limit
              placeholder="请输入检测名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交</el-button
            >
          </el-form-item>
        </div>
      </el-form>
      <el-dialog
        title="检测项目列表"
        :visible.sync="innerVisible"
        width="30%"
        center
        append-to-body
      >
        <el-table
          :data="Form.detectionProject"
          style="width:100%;"
          row-key="id"
          border
          max-height="300px"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="name" label="姓名">
            <template slot-scope="scope">
              <span>{{ scope.row.name ? scope.row.name : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="adddetectionProject(scope.row)"
                >修 改</el-button
              >
              <template v-if="!!scope.row.children">
                <el-button size="mini" @click="adddetection(scope.row)"
                  >添 加</el-button
                >
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          append-to-body
          center
          width="30%"
          title="修改检测项目"
          :visible.sync="alter"
          @close="closealter"
        >
          <div>
            <b style="margin-right:20px">检测项目名称</b>
            <el-input
              placeholder="请输入检测项目名称"
              v-model="project.name"
              style="width:217px"
              clearable
            ></el-input>
          </div>
          <div style="text-align:right">
            <el-button type="primary" @click="addsubmit"> 确 认</el-button>
          </div>
        </el-dialog>
        <div style="text-align:right;margin-top:20px">
          <el-button type="primary" @click="addstatus">添 加</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="检测机构列表"
        :visible.sync="organization1"
        width="30%"
        center
        append-to-body
      >
        <el-table
          :data="Form.detectionorganizationid"
          style="width:100%;"
          row-key="id"
          border
          max-height="300px"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="name" label="检测机构名称"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="adddetectionorg(scope.row)"
                >修 改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align:right;margin-top:20px">
          <el-button type="primary" @click="organization2 = true"
            >添 加</el-button
          >
        </div>
        <el-dialog
          title="添加检测机构"
          :visible.sync="organization2"
          width="30%"
          center
          append-to-body
          @close="closeclearable"
        >
          <div>
            <b style="margin-right:20px">添加检测机构名称</b>
            <el-input
              clearable
              v-model="organization.name"
              placeholder="请输入检测名称"
              style="width:217px"
            ></el-input>
          </div>
          <div style="text-align:right;margin-top:20px">
            <el-button type="primary" @click="organizationsubmit"
              >确 认</el-button
            >
          </div>
        </el-dialog>
      </el-dialog>
      <el-dialog
        title="检测人员列表"
        :visible.sync="people1"
        width="30%"
        center
        append-to-body
      >
        <el-table
          :data="Form.detectionpeopleid"
          style="width:100%;"
          row-key="id"
          border
          max-height="300px"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="name" label="检测人员姓名"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="addname(scope.row)"
                >修 改</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align:right;margin-top:20px">
          <el-button type="primary" @click="people2 = true">添 加</el-button>
        </div>

        <el-dialog
          title="添加检测人员"
          :visible.sync="people2"
          width="30%"
          center
          append-to-body
          @close="projectlearable"
        >
          <div>
            <b style="margin-right:20px">选择检测机构</b>
            <el-select
              v-model="peopleid.detection_organization_id"
              placeholder="请选择检测机构"
            >
              <el-option
                v-for="item in Form.detectionorganizationid"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div>
            <b style="margin-right:20px">添加检测人员姓名</b>
            <el-input
              clearable
              v-model="peopleid.name"
              placeholder="请输入检测名称"
              style="width:217px"
            ></el-input>
          </div>
          <div style="text-align:right;margin-top:20px">
            <el-button type="primary" @click="addpeople">确 认</el-button>
          </div>
        </el-dialog>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { getmarket, GETsearchbooth } from '../../../api/layout'
import {
  getSellerByMarketId,
  GETShopBySellerId,
  getProductBySellerId
} from '../../../api/productList'

import {
  GETdetectionProjects,
  GETorganizations,
  GETpeoples,
  PATCHdetectionProjects,
  POSTetectionProjects,
  POSTorganizations,
  PATCHorganizations,
  POSTpeoples,
  PATCHpeoples,
  POSTinformation,
  GETinformation,
  PATCHinformation
} from '../../../api/testingList'
import { upload } from '../../../utile/upload'
export default {
  name: 'testingList',
  data () {
    return {
      // 搜索数据
      search: {
        market_id: '', // 市场
        seller_id: '', // 商户
        detection_project_id: '', // 检测项目
        detection_people_id: '', // 检测人员
        result: '', // 检测结论
        date: '', // 检测时间
        perPage: 10, // 每页的条数
        page: 1 // 当前页码
      },
      // 标题
      title: '添加检测信息',
      titleID: '',
      centerDialogVisible: false,
      // 检测机构
      organization1: false,
      organization2: false,
      organization: {
        name: '',
        id: ''
      },
      // 检测项目
      innerVisible: false,
      alter: false,
      status: false,
      project: {
        parent_id: '',
        name: ''
      },
      // 检测人员
      people1: false,
      people2: false,
      peopleid: {
        detection_people: '',
        name: '',
        detection_organization_id: ''
      },
      // 大表单数据
      Form: {
        marketid: '', // 市场
        sellerid: '', // 商户
        sellershopid: '', // 商户店铺
        productsellerid: '', // 商户商品
        boothid: '', // 摊位
        detectionProject: [], // 检测项目
        detectionorganizationid: [], // 检测机构
        detectionpeopleid: [] // 检测人员
      },
      ruleForm: {
        market_id: '', // 市场ID
        seller_id: '', // 商户ID
        seller_shop_id: '', // 商户店铺
        booth_id: '', // 摊位ID
        detect_name: '', // 检测名称
        batch_id: '', // 进货批次号或交易凭证号
        product_seller_id: '', // 商户商品ID
        sample_id: '', // 样品编号
        detection_project_id: [], // 检测项目ID
        detection_organization_id: '', // 检测机构ID
        detection_people_id: '', // 检测人员ID
        date: '', // 检测日期
        detection_num: '', // 检测编号
        value: '', // 检测值
        result: '', // 检测结果，是否合格 1-合格 0-不合格 2-疑似
        result_expl: '', // 检测结果说明，说明是否异常，指出异常参数
        detection_product_image: '', // 检测商品图片
        detection_report_image: '' // 检测报告图片
      },
      rules: {
        market_id: [{ required: true, message: '请选择市场' }],
        seller_id: [{ required: true, message: '请选择商户' }],
        booth_id: [{ required: true, message: '请选择商户的店铺' }],
        detect_name: [{ required: true, message: '请输入检测名称' }],
        batch_id: [{ required: true, message: '进货批次号或交易凭证号' }],
        product_seller_id: [{ required: true, message: '请选择商户商品' }],
        sample_id: [{ required: true, message: '请输入样品编号' }],
        detection_project_id: [{ required: true, message: '请选择检测项目' }],
        detection_organization_id: [
          { required: true, message: '请选择检测机构' }
        ],
        detection_people_id: [{ required: true, message: '请选择检测人员' }],
        date: [{ required: true, message: '请选择检测日期' }],
        detection_num: [{ required: true, message: '请输入检测编号' }],
        value: [{ required: true, message: '请输入检测值' }],
        result: [{ required: true, message: '请选择检测结果' }],
        result_expl: [
          {
            required: true,
            message: '请输入检测结果说明，说明是否异常，指出异常参数'
          }
        ],
        // detection_product_image: [
        //   { required: true, message: '请上传检测商品图片' }
        // ],
        // detection_report_image: [
        //   { required: true, message: '请上传检测报告图片' }
        // ],
        seller_shop_id: [{ required: true, message: '请选择商户店铺' }]
      },
      tableData: [],
      total: 0
    }
  },
  methods: {
    // 表格数据
    GET_information () {
      const params = {
        ...this.search,
        detection_project_id: this.search.detection_project_id[0],
        detection_sub_project_id: this.search.detection_project_id[1],
        detection_three_project_id: this.search.detection_project_id[2]
          ? this.search.detection_project_id[2]
          : ''
      }
      GETinformation(params).then(res => {
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    GET_searchbooth () {
      GETsearchbooth().then(res => {
        this.Form.boothid = res.data
      })
    },
    // 市场
    get_market () {
      getmarket().then(res => {
        this.Form.marketid = res.data
      })
    },
    // 商户
    clickmarketid () {
      this.ruleForm.seller_id = ''
      this.ruleForm.seller_shop_id = ''
      this.ruleForm.product_seller_id = ''
      getSellerByMarketId(this.ruleForm.market_id).then(res => {
        this.Form.sellerid = res.data
      })
    },
    // 店铺
    clicksellerid () {
      this.ruleForm.seller_shop_id = ''
      this.ruleForm.product_seller_id = ''
      GETShopBySellerId(this.ruleForm.seller_id).then(res => {
        this.Form.sellershopid = res.data
      })
    },
    // 商户商品
    clicksellershopid () {
      this.ruleForm.product_seller_id = ''
      getProductBySellerId(
        this.ruleForm.market_id,
        this.ruleForm.seller_id
      ).then(res => {
        this.Form.productsellerid = res.data
      })
    },
    // 检测项目
    GETdetection_Projects () {
      GETdetectionProjects().then(res => {
        res.data.forEach(item => {
          if (item.children.length > 0) {
            item.children.forEach(items => {
              if (items.children.length === 0) {
                delete items.children
              }
            })
          }
        })
        this.Form.detectionProject = res.data
      })
    },
    // 检测机构
    GET_organizations () {
      GETorganizations().then(res => {
        this.Form.detectionorganizationid = res.data.data
        // this.GET_peoples()
      })
    },

    // ShopBySellerId
    // 检测人员
    peoples (item) {
      // alert(item)
      GETpeoples(this.ruleForm.detection_organization_id).then(res => {
        this.Form.detectionpeopleid = res.data.data
      })
    },
    // 检测项目修改
    adddetectionProject (row) {
      this.alter = true
      this.project.name = row.name
      this.project.parent_id = row.id
    },
    // 检测项目添加
    adddetection (row) {
      this.alter = true
      this.status = true
      this.project.parent_id = row.id
    },
    closealter () {
      this.project.parent_id = ''
      this.project.name = ''
    },
    addstatus () {
      this.alter = true
      this.status = true
    },
    // 检测项目确认
    addsubmit () {
      if (!this.project.name) {
        this.$message.error('请输入检测项目名称')
        return
      }
      if (this.status) {
        POSTetectionProjects(this.project)
          .then(res => {
            if (res.code === 200) {
              this.alter = false
              this.status = false
              this.$message.success(`${res.data}`)
              this.GETdetection_Projects()
              this.project = { name: '', parent_id: '' }
            }
          })
          .catch(err => {
            this.$message.error(`${err.msg}`)
          })
      } else {
        PATCHdetectionProjects({
          data: this.project,
          id: this.project.parent_id
        })
          .then(res => {
            if (res.code === 200) {
              this.alter = false
              this.$message.success(`${res.data}`)
              this.GETdetection_Projects()
            }
          })
          .catch(err => {
            this.$message.error(`${err.msg}`)
          })
      }
    },
    // 检测机构添加
    organizationsubmit () {
      if (!this.organization.name) {
        this.$message.error('请输入检测机构的名称')
        return
      }
      if (this.organization.id) {
        PATCHorganizations(this.organization, this.organization.id).then(
          res => {
            if (res.code === 200) {
              this.organization = {
                id: '',
                name: ''
              }
              this.$message.success(`${res.data}`)
              this.organization2 = false
              this.GET_organizations()
            } else {
              this.$message.error(`${res.msg}`)
            }
          }
        )
      } else {
        POSTorganizations(this.organization).then(res => {
          if (res.code === 200) {
            this.organization = {
              id: '',
              name: ''
            }
            this.$message.success(`${res.data}`)
            this.organization2 = false
            this.GET_organizations()
          } else {
            this.$message.error(`${res.msg}`)
          }
        })
      }
    },
    adddetectionorg (row) {
      this.organization2 = true
      this.organization = {
        id: row.id,
        name: row.name
      }
    },
    closeclearable () {
      this.organization = {
        id: '',
        name: ''
      }
    },
    // 添加检测人员
    addpeople () {
      if (!this.peopleid.name) {
        this.$message.error('请输入检测人员姓名')
      }
      if (!this.peopleid.detection_people) {
        const obj = {
          name: this.peopleid.name,
          detection_organization_id: this.peopleid.detection_organization_id
        }

        POSTpeoples(obj).then(res => {
          if (res.code === 200) {
            this.peopleid = {
              detection_people: '',
              name: '',
              detection_organization_id: ''
            }
            this.$message.success(`${res.data}`)
            this.people2 = false
            this.peoples()
          } else {
            this.$message.error(`${res.msg}`)
          }
        })
      } else {
        PATCHpeoples(this.peopleid, this.peopleid.detection_people).then(
          res => {
            if (res.code === 200) {
              this.peopleid = {
                detection_people: '',
                name: '',
                detection_organization_id: ''
              }
              this.$message.success(`${res.data}`)
              this.people2 = false
              this.peoples()
            } else {
              this.$message.error(`${res.msg}`)
            }
          }
        )
      }
    },
    addname (row) {
      console.log(row)
      this.people2 = true
      this.peopleid = {
        detection_people: row.id,
        name: row.name,
        detection_organization_id: row.detection_organization_id
      }
    },
    projectlearable () {
      this.peopleid = {
        id: '',
        name: ''
      }
    },
    // 大表格提交
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            ...this.ruleForm,
            detection_project_id: this.ruleForm.detection_project_id[0],
            detection_sub_project_id: this.ruleForm.detection_project_id[1],
            detection_three_project_id: this.ruleForm.detection_project_id[2]
              ? this.ruleForm.detection_project_id[2]
              : ''
          }
          if (this.title === '添加检测信息') {
            POSTinformation(data).then(res => {
              if (res.code === 200) {
                this.$message.success(`${res.data}`)
                this.closecenterDialogVisible()
                this.centerDialogVisible = false
                this.GET_information()
              } else {
                this.$message.error(`${res.msg}`)
              }
            })
          } else {
            PATCHinformation(data, this.titleID).then(res => {
              if (res.code === 200) {
                this.$message.success(`${res.data}`)
                this.closecenterDialogVisible()
                this.centerDialogVisible = false
                this.GET_information()
              } else {
                this.$message.error(`${res.msg}`)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    closecenterDialogVisible () {
      this.title = '添加检测信息'
      this.ruleForm = {
        market_id: '', // 市场ID
        seller_id: '', // 商户ID
        seller_shop_id: '', // 商户店铺
        booth_id: '', // 摊位ID
        detect_name: '', // 检测名称
        batch_id: '', // 进货批次号或交易凭证号
        product_seller_id: '', // 商户商品ID
        sample_id: '', // 样品编号
        detection_project_id: '', // 检测项目ID
        detection_organization_id: '', // 检测机构ID
        detection_people_id: '', // 检测人员ID
        date: '', // 检测日期
        detection_num: '', // 检测编号
        value: '', // 检测值
        result: '', // 检测结果，是否合格 1-合格 0-不合格 2-疑似
        result_expl: '', // 检测结果说明，说明是否异常，指出异常参数
        detection_product_image: '', // 检测商品图片
        detection_report_image: '' // 检测报告图片
      }
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径 营业执照
    uploadSectionFile1 (param) {
      // this.ruleForm.detection_product_image = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.detection_product_image = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    uploadSectionFile2 (param) {
      // this.ruleForm.detection_report_image = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.detection_report_image = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 修改按钮
    async alterChang (row) {
      this.title = '修改检测信息'
      this.titleID = row.id
      this.ruleForm.booth_id = row.booth_id

      this.ruleForm.detect_name = row.detect_name
      this.ruleForm.sample_id = row.sample_id
      this.ruleForm.detection_project_id = [
        row.detection_project_id,
        row.detection_sub_project_id
      ]
      if (row.detection_three_project_id) {
        this.ruleForm.detection_project_id.push(row.detection_three_project_id)
      }
      this.ruleForm.detection_organization_id = row.detection_organization_id
      this.ruleForm.detection_people_id = row.detection_people_id
      this.ruleForm.date = row.date
      this.ruleForm.detection_num = row.detection_num
      this.ruleForm.value = row.value
      this.ruleForm.result = row.result
      this.ruleForm.result_expl = row.result_expl
      this.ruleForm.detection_product_image = row.detection_product_image
      this.ruleForm.detection_report_image = row.detection_report_image
      this.ruleForm.market_id = row.market_id
      await this.clickmarketid()
      this.ruleForm.seller_id = row.seller_id
      await this.clicksellerid()
      this.ruleForm.seller_shop_id = row.seller_shop_id
      await this.clicksellershopid()
      this.ruleForm.product_seller_id = row.product_seller_id
      this.centerDialogVisible = true
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.search.perPage = val
      this.GET_information()
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.search.page = val
      this.GET_information()
    }
  },
  mounted () {
    this.GET_information()
    this.get_market()
    this.GET_searchbooth()
    this.GETdetection_Projects()
    // this.GET_peoples()
    this.GET_organizations()
  }
}
</script>

<style lang="less" scoped>
.footers {
  text-align: right;
  margin-top: 10px;
}
.title1 {
  display: flex;
  justify-content: space-between;
  width: 90%;
}
.title1-list {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
