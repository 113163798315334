import request from './request'

// 检测项目
export const GETdetectionProjects = () => {
  return request({
    url: 'admin/v1/detection_projects',
    method: 'GET'
  })
}
// 检测项目
export const PATCHdetectionProjects = ({ data, id }) => {
  return request({
    url: `admin/v1/detection_projects/${id}`,
    method: 'PATCH',
    data
  })
}
// 检测项目
export const POSTetectionProjects = data => {
  return request({
    url: 'admin/v1/detection_projects',
    method: 'POST',
    data
  })
}

// 检测机构列表
export const GETorganizations = () => {
  return request({
    url: 'admin/v1/detection_organizations',
    method: 'GET'
  })
}
// 检测机构列表
export const PATCHorganizations = (data, id) => {
  return request({
    url: `admin/v1/detection_organizations/${id}`,
    method: 'PATCH',
    data
  })
}
// 检测机构列表
export const POSTorganizations = data => {
  return request({
    url: 'admin/v1/detection_organizations',
    method: 'POST',
    data
  })
}
// 检测人员列表
export const GETpeoples = id => {
  return request({
    url: 'admin/v1/detection_peoples?detection_organization_id=' + id,
    method: 'GET'
  })
}
// 新增检测人员
export const POSTpeoples = data => {
  return request({
    url: 'admin/v1/detection_peoples',
    method: 'POST',
    data
  })
}
// 更新检测人员
export const PATCHpeoples = (data, id) => {
  return request({
    url: `admin/v1/detection_peoples/${id}`,
    method: 'PATCH',
    data
  })
}
// 新增检测信息
export const POSTinformation = data => {
  return request({
    url: 'admin/v1/detection_information',
    method: 'POST',
    data
  })
}
// 检测信息列表
export const GETinformation = params => {
  return request({
    url: 'admin/v1/detection_information',
    method: 'GET',
    params
  })
}
// 更新检测信息
export const PATCHinformation = (data, id) => {
  return request({
    url: `admin/v1/detection_information/${id}`,
    method: 'PATCH',
    data
  })
}
